//BRAND
export const name = 'Course GEN Pro';
export const company = 'Bluebridge Works, LLC';
export const websiteURL = 'https://coursegenpro.com';
export const serverURL = 'https://coursegenpro.onrender.com';
export const logo = 'https://firebasestorage.googleapis.com/v0/b/coursegenpro-2bcf2.appspot.com/o/1.png?alt=media&token=c3f9fcd4-851a-425a-83df-836d23029dda';
export const razorpayEnabled = false;
export const paypalEnabled = true;

//PRICING :-

//FREE 
export const FreeType = 'Free Plan';
export const FreeCost = 0;
export const FreeTime = '';

//MONTHLY 
export const MonthType = 'Monthly Plan';
export const MonthCost = 9;
export const MonthTime = 'month';

//YEARLY 
export const YearType = 'Yearly Plan';
export const YearCost = 99;
export const YearTime = 'year';

//TESTIMONIAL
export const review = "The AI Course Generator revolutionized my content creation process, providing accurate and relevant topics effortlessly. It's a time-saving powerhouse that enhances the quality and relevance of my courses. A must-have tool for educators seeking efficiency and impactful online learning experiences.";
export const from = "Bluebridge Works";
export const profession = 'Course GEN Pro';
export const photoURL = 'https://play-lh.googleusercontent.com/sV_ffBmBJt_je4RZHnfaCfcnL-Hy6C14Iol7H5EMj9fzI2GDOonuojdn5t9p6n9IAX8j';

//PAYPAL
export const paypalPlanIdOne = "P-3P027245K3358714VMY2QU5A";
export const paypalPlanIdTwo = "P-1TH30810W3508261RMY2QWCY";

//RAZORPAY
export const razorpayKeyId = "rzp_test_uqALjZHyTyuiOm";
export const razorpayPlanIdOne = "plan_NMvvtDfznbRp6V";
export const razorpayPlanIdTwo = "plan_NMRc9HARBQRLWA";
